@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: monalisa;
  src: url("../public/Font/MonoLisa-Regular.ttf");
}
#root {
  height: 100%;
}
body {
  height: 100%;
  background-color: #0a0a0a;
}
.dark {
  --color-bg-primary: #fe4b03;
  --color-bg-secondary: #0a0a0a;
  --color-bg-accent: #1f1f1e;
  --color-text-primary: #fe4b03;
  --color-text-secondary: #171717;
  --color-text-accent: #edf2f7;
}

.light {
  --color-bg-primary: #fe4b03;
  --color-bg-secondary: #fff;
  --color-bg-accent: #1f1f1e;
  --color-text-primary: #fe4b03;
  --color-text-secondary: #1f1f1e;
  --color-text-accent: #edf2f7;
}
