#root {
  height: 100%;
}
html,
body {
  height: 100% !important;
  position: relative !important;
}

body {
  font-family: monalisa;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100% !important;
  height: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;

}

.link-underline {
  padding: 10px;
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fe4b03, #fe4b03);
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

.link-underline-black {
  margin-left: 2px;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fe4b03, #fe4b03);
}

.link-underline:hover {
  margin-left: 2px;
  background-size: 100% 1px;
  background-position: 0 100%;
}
.swiper-pagination-bullet-active {
  background-color: #ae1a11 !important;
}
.swiper-pagination-bullet {
  right: 40px;
  border: 0.5px #ae1a11 solid;
}

.swiper {
  /* width: 100% !important; */
  height: 100% !important;
}

/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

} */

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.link-underline2 {
  /* padding: 10px; */
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

.link-underline-black2 {
  margin-left: 2px;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);
}

.link-underline2:hover {
  margin-left: 2px;
  background-size: 100% 1px;
  background-position: 0 100%;
}

#parent:hover #child {
  background-color: black !important;
  color: white !important;
  /* background: red !important; */
}
#parent:hover #titleChild {
  color: black !important;
}
#Slide {
  width: 240px !important ;
  height: 320px !important;
}
/* .test-slide {
  background-color: #ae1a11 !important;
  background-image: url("../img/24.jpg") !important;
} */
